<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex justify-content-between align-items-center px-4 pt-4">
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Artículos</h3>
            <article class="d-flex justify-content-between align-items-center">
              <vs-button @click="$router.push('NuevoArticulo')">Nuevo Artículo</vs-button>
              <vs-button class='bg-success'>Inventario</vs-button>
            </article>
          </div>
          <div class="table-responsive px-4 mt-5">
            <table id="datatable_articulo" class="table table-bordered table-responsive-lg table-striped text-center">
              <thead>
                <tr>
                  <th>
                    Nombre
                  </th>
                  <th style='width: 20rem;'>
                    Descripción
                  </th>
                  <th>
                    Tipo
                  </th>
                  <th>
                    Categoría
                  </th>
                  <th>
                    Stock
                  </th>
                  <th style='width: 9rem;'>
                    Precios de Venta
                  </th>
                  <th style='width: 9rem;'>
                    Precios de Compra
                  </th>
                  <th style='width: 6rem;'>
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in article" :key="item.id">
                  <td class='font-weight-bold'>
                    {{ item.nombre }}
                  </td>
                  <td>
                    <b-button variant="trash-outline" v-b-tooltip.hover :title="item.descripcion">
                      {{ item.descripcion.length > 50 ? item.descripcion.substring(0, 50) + '...' : item.descripcion }}
                    </b-button>
                  </td>
                  <td>
                    {{ item.tipo }}
                  </td>
                  <td>
                    {{ item.categoria }}
                  </td>
                  <td>
                    {{ item.stock }}
                  </td>
                  <td>
                    {{ item.precioVenta }}
                  </td>
                  <td>
                    {{ item.precioCompra }}
                  </td>
                  <td>
                    <div class='d-flex'>
                      <vs-button @click="$router.push(`EditarArticulo/${item.id}`)"><ion-icon
                          name="create-outline"></ion-icon></vs-button>
                      <vs-button class='bg-danger' @click='deleteArticulo(item.id)'><ion-icon
                          name="trash-outline"></ion-icon></vs-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from "../../../../../config/pluginInit";
  import Swal from 'sweetalert2';
  export default {
    data: () => ({
      article: [],
      empresaId: null,
    }),
    async mounted() {
      const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
      if (valor) {
        this.empresaId = valor.empresa.id;
      }

      core.index();
      this.article = [];
      await this.getData();
      window.$("#datatable_articulo").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
    methods: {
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: 'CONTArticulo/ObtenerArticuloEmpresaId/' + this.empresaId,
          });

          if (res.data.length > 0) {
            this.article = res.data.map(item => ({
              id: item.Id,
              nombre: item.Nombre,
              descripcion: item.Descripcion,
              tipo: item.Tipo,
              stock: item.Stock,
              precioCompra: item.PrecioCompra,
              precioVenta: item.PrecioVenta,
              categoria: item.Categoria
            }));
          } else {
            this.article = [];
          }

        } catch (error) {
          console.error("Error al obtener la lista el articulo:", error);
          this.article = [];
        }
      },
      async deleteArticulo(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar el Articulo?',
          text: "¡No podrás revertir esta acción!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("hl_delete", {
                path: `CONTArticulo/EliminarArticulo/${id}`,
              });
              Swal.fire("Listo!", "Articulo eliminado Correctamente", "success");
              await this.getData();
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar el Articulo", "error");
            }
          }
        });
      }
    }
  }
</script>